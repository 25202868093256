<template>
  <div class="tw-flex tw-justify-center">
    <div class="tw-flex tw-justify-center">
      <div class="page-sculptor">
        <div>
          <v-btn class="add-pd" @click="routeBack">
            <icons name="back" class="mr-2" />
            All Payments
          </v-btn>
        </div>
        <div class="white-bg tw-flex-wrap">
          <v-col sm="5" cols="12">
            <div class="image-border">
              <img src="@/assets/wallet.png" />
            </div>
          </v-col>
          <v-col sm="7" cols="12">
            <div class="tw-flex">
              <v-col class="lighter-text" cols="6" sm="6">PaymentId</v-col>
              <v-col class="darker-text" cols="6" sm="6">{{ selectedPayment.paymentReference }}</v-col>
            </div>
            <div class="tw-flex">
              <v-col class="lighter-text" cols="6" sm="6">Full Name</v-col>
              <v-col class="darker-text" cols="6" sm="6">{{ getFullName(selectedPayment) }}</v-col>
            </div>
            <div class="tw-flex">
              <v-col class="lighter-text" cols="6" sm="6">Email</v-col>
              <v-col class="darker-text" cols="6" sm="6">
                <span style="text-transform:lowercase">{{ selectedPayment.customerEmail }}</span></v-col>
            </div>

            <div class="tw-flex">
              <v-col class="lighter-text" cols="6" sm="6">Amount</v-col>
              <v-col class="darker-text" cols="6" sm="6">₦{{ selectedPayment.amount }}</v-col>
            </div>

            <div class="tw-flex">
              <v-col class="lighter-text" cols="6" sm="6">Status</v-col>
              <v-col class="darker-text" cols="6" sm="6">
                <v-chip v-if="selectedPayment.paymentPurposeStatus.toLowerCase() ==='successful'" color="#00C48C69">
                  <span class="darker-text" style="color: #0F674E; font-size:10px">
                    Success
                  </span>
                </v-chip>
                <v-chip v-if="selectedPayment.paymentPurposeStatus.toLowerCase() ==='failed'" color="#C8486759">
                  <span class="darker-text" style="color: #71011C; font-size:10px">
                    Failed
                  </span>
                </v-chip>

              </v-col>
            </div>
          </v-col>

        </div>
        <div class="my-4"></div>
        <div class="white-bg tw-flex tw-flex-wrap">
          <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
            <span class="lighter-text">
              Purpose
            </span>
            <span class="darker-text mt-3">
              {{ selectedPayment.paymentPurpose }}
            </span>
          </v-col>
          <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
            <span class="lighter-text">
              Account Name
            </span>
            <span class="darker-text mt-3">
              {{ selectedPayment.receivingAccountName }}
            </span>
          </v-col>
          <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
            <span class="lighter-text">
              Account No.
            </span>
            <span class="darker-text mt-4">
              {{ selectedPayment.receivingAccountNumber }}
            </span>
          </v-col>
          <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
            <span class="lighter-text">
              Booking ID
            </span>
            <span class="darker-text mt-4">
              {{ selectedPayment.id }}
            </span>
          </v-col>
          <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
            <span class="lighter-text">
              Payment Time
            </span>
            <span class="darker-text mt-4">
              {{ formatDateTime(selectedPayment.createdAt) }}
            </span>
          </v-col>
          <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
            <span class="lighter-text">
              Departure
            </span>
            <span class="darker-text mt-4">
              {{ selectedPayment.departure }}
            </span>
          </v-col>
          <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
            <span class="lighter-text">
              Arrival
            </span>
            <span class="darker-text mt-4">
              {{ selectedPayment.destination }}
            </span>
          </v-col>
          <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
            <span class="lighter-text">
              Selected seat
            </span>
            <span class="darker-text mt-4">
              {{selectedPayment.selectedSeatNumbers }}
            </span>
          </v-col>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
  import {
    mapGetters
  } from 'vuex';
  import Icons from '../../components/reuseables/Icons.vue';
  export default {
    components: {
      Icons
    },
    name: "ViewPayment",
    data() {
      return {};
    },
    props: {},
    methods: {
      formatDateTime(dateTime) {
        const options = {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
          day: 'numeric',
          month: 'long',
          year: 'numeric'
        };
        const formattedDateTime = new Date(dateTime).toLocaleString('en-US', options);
        const formattedDateWithoutAt = formattedDateTime.replace(' at', ', ')
        return formattedDateWithoutAt;
      },
      routeBack() {
        this.$router.push({
          name: 'PaymentDashboard'
        })
      },
      getFullName(selectedPayment) {
        if (selectedPayment && selectedPayment.travellers && selectedPayment.travellers[0]) {
          return selectedPayment.travellers[0].firstName.trim() + " " + selectedPayment.travellers[0].lastName.trim();
        } else {
          return '';
        }
      }
    },
    computed: {
      ...mapGetters('paymentLog', ['selectedPayment'])
    },
    created() {
      // console.log(this.selectedPayment);
    }
  };
</script>

<style lang="scss" scoped>
  .add-pd {
    color: #263238;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: none;
    letter-spacing: 0;
    margin-bottom: 15px;

    @media (max-width:768px) {
      justify-content: start;
      margin-top: 13px;
      font-size: 17px;
    }
  }

  .lighter-text,
  .darker-text {
    color: #939393;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize !important;
    line-height: 20px;
  }

  .darker-text {
    color: #263238;
  }

  .white-bg {
    background-color: #fff;
    padding: 30px;
    height: fit-content;
    border-radius: 10px;
    display: flex;

    @media (max-width:768px) {
      padding: 15px;
    }
  }

  .image-border {
    border-radius: 10px;
    padding: 45px 30px;
    display: flex;
    justify-content: center;
    border: 2px dashed rgba(15, 133, 99, 0.42);
    background: rgba(15, 133, 99, 0.06);

    @media (max-width:768px) {
      padding: 20px;
    }
  }

  .page-sculptor {
    display: flex;
    flex-direction: column;
    width: 650px;

    @media (max-width:768px) {
      width: 100%;
    }
  }

  .v-chip.v-size--default {
    width: 69px;
    height: 24px;
    display: flex;
    justify-content: center;
    font-size: 10px !important;
  }

  .text-headings {
    color: #4F4F4F;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
</style>